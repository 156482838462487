import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  Report,
  ReportName,
  ReportResponse,
  TotalItemPerStageFilter,
  TotalOrdersByStatusWithSLA,
  TotalProductTypesPerOrderStatusFilter,
  TotalProductTypesPerOrderStatusReport,
} from '@core/reports';
import { TIMEZONE_REQUIRED } from '@coreauth/interceptors/api.interceptor';
import { SLAProductInfo, SLAPerformanceInfo, SLAShipmentInfo, SLAShippingQueue } from '@core/reports';

export interface ItemsPerStage {
  stage: string;
  total: number;
}

export type ItemsPerStageReport = ItemsPerStage[];

@Injectable({ providedIn: 'root' })
export class ReportService {
  private static BASE_URL = '/reports';
  private static SLA_URL = `${ReportService.BASE_URL}/sla`;

  private http$ = inject(HttpClient);

  getReport(filter?: { [key: string]: any }): Observable<ReportResponse> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter });

    return this.http$.get<ReportResponse>(url, {
      params,
      context: new HttpContext().set(TIMEZONE_REQUIRED, true),
    });
  }

  getSLAShippingQueue(filter: any): Observable<Report<SLAShippingQueue[]>> {
    const params = new HttpParams({ fromObject: filter }).set('report', ReportName.sla_performance_shipping_queue);

    return this.http$.get<Report<SLAShippingQueue[]>>(ReportService.SLA_URL, {
      params,
      context: new HttpContext().set(TIMEZONE_REQUIRED, true)
    });
  }

  getSLAPerformanceInfo(filter: any, offset: number = 0): Observable<Report<SLAPerformanceInfo>> {
    const params = new HttpParams({ fromObject: filter })
      .set('report', ReportName.sla_performance_info)
      .set('offset', offset);

    return this.http$.get<Report<SLAPerformanceInfo>>(ReportService.SLA_URL, {
      params,
      context: new HttpContext().set(TIMEZONE_REQUIRED, true)
    });
  }

  getSLAProductsInfo(filter: any, offset: number = 0): Observable<Report<SLAProductInfo[]>> {
    const params = new HttpParams({ fromObject: filter })
      .set('report', ReportName.sla_performance_products_info)
      .set('offset', offset);

    return this.http$.get<Report<SLAProductInfo[]>>(ReportService.SLA_URL, {
      params,
      context: new HttpContext().set(TIMEZONE_REQUIRED, true)
    });
  }

  getSLAShipmentsInfo(filter: any, offset: number = 0): Observable<Report<SLAShipmentInfo[]>> {
    const params = new HttpParams({ fromObject: filter })
      .set('report', ReportName.sla_performance_shipments_info)
      .set('offset', offset);

    return this.http$.get<Report<SLAShipmentInfo[]>>(ReportService.SLA_URL, {
      params,
      context: new HttpContext().set(TIMEZONE_REQUIRED, true)
    });
  }

  getTotalOrdersPerStatusWithSLA(filter: any): Observable<Report<TotalOrdersByStatusWithSLA>> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter }).set('report', ReportName.TotalOrdersPerStatusWithSLA);

    return this.http$.get<Report<TotalOrdersByStatusWithSLA>>(url, { params });
  }

  getTotalItemsPerStage(filter: Partial<TotalItemPerStageFilter>): Observable<Report<ItemsPerStageReport>> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter }).set('report', ReportName.TotalItemsPerStage);

    return this.http$.get<Report<ItemsPerStageReport>>(url, { params });
  }

  getTotalProductTypesPerOrderStatus(filter: Partial<TotalProductTypesPerOrderStatusFilter>): Observable<Report<TotalProductTypesPerOrderStatusReport>> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter as any }).set('report', ReportName.TotalProductTypesPerOrderStatus);

    return this.http$.get<Report<TotalProductTypesPerOrderStatusReport>>(url, { params });
  }
}

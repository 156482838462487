import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { FormlyFieldType } from '../model';

export const FormlyDateRangeKey = 'dateRange';

export const createFormlyDateField = (options: {
  label?: string;
  className?: string;
  defaultValue?: { startDate: string | Date; endDate?: string | Date; },
  emitDefaultValue?: boolean;
  templateOptions?: { [key: string]: any },
  props?: { [key: string]: any },
  expressions?: { [key: string]: any },
}): FormlyFieldConfig => {
  return {
    className: options.className || 'col-lg-12 col-3',
    type: FormlyFieldType.DateRange,
    key: FormlyDateRangeKey,
    defaultValue: options.defaultValue,
    props: {
      label: options.label || 'Date',
      defaultValue: options.defaultValue,
      emitDefaultValue: options.emitDefaultValue,
      ...options.templateOptions,
      ...options.props,
    },
    ...(options.expressions ? { expressions: options.expressions } : {}),
  };
};

export const extractFormlyDateRange = () => {
  return <T>(source: Observable<T>): Observable<T> => {
    return new Observable(subscriber => {
      source.subscribe({
        next: (value) => {
          const { dateRange, ...filter } = value as { dateRange: { startDate: any; endDate: any } };

          subscriber.next({
            ...filter,
            ...(dateRange?.startDate && { startDate: dateRange.startDate }),
            ...(dateRange?.endDate && { endDate: dateRange.endDate }),
          });
        },
        error: (error) => {
          subscriber.error(error);
        },
        complete: () => {
          subscriber.complete();
        }
      })
    });
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'kt-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent {
  @Input() bgColor?: string;
  @Input() active?: boolean;
  @Input() textColor?: string;
  @Input() cursor?: 'auto' | 'pointer' | 'zoom-out' | 'zoom-in' | 'help' | 'wait' | 'crosshair' | 'not-allowed' | 'grab' | 'inherit' | 'initial' | 'revert' | 'unset';
  protected hovered = false;
}

import { NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

type AlignPosition = 'left' | 'center' | 'right';

const alignPositions: { [key in AlignPosition]: string } = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end',
};
const defaultAlignPosition = 'center';

@Component({
  standalone: true,
  selector: 'kt-simple-preloader',
  templateUrl: './simple-preloader.component.html',
  styleUrls: ['./simple-preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class SimplePreloaderComponent implements AfterViewInit {

  @Input() align: AlignPosition = defaultAlignPosition;

  alignPosition: string = defaultAlignPosition;

  ngAfterViewInit(): void {
    this.alignPosition = alignPositions[this.align || defaultAlignPosition];
  }

}

import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject, signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { DateRange as MatDateRange, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { DateUtil } from '@core/utils';
import { GenericButtonComponent } from '@shared/ui';
import { AttachDeviceWidthDirective } from '@core/directives';
import { DateRange } from '@core/models';

enum DateRangeSelectorView {
  presets = 'presets',
  calendar = 'calendar',
}

@Component({
  selector: 'kt-date-range-selector',
  standalone: true,
  templateUrl: './date-range-selector.component.html',
  styleUrl: './date-range-selector.component.scss',
  providers: [provideNativeDateAdapter()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AttachDeviceWidthDirective,
    DatePipe,
    GenericButtonComponent,
    MatCardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatListModule,
    NgIf,
  ],
})
export class DateRangeSelectorComponent {
  static DEFAULT_PRESET_INDEX = 4;
  readonly presets: (DateRange & { title: string; type: 'past' | 'future' })[] = [
    {
      title: 'Next Day',
      startDate: new DateUtil().startOfDay().add(1, 'day').toDate(),
      endDate: new DateUtil().endOfDay().add(1, 'day').toDate(),
      type: 'future',
    },
    {
      title: 'Today',
      startDate: new DateUtil().startOfDay().toDate(),
      endDate: new DateUtil().endOfDay().toDate(),
      type: 'past',
    },
    {
      title: 'Yesterday',
      startDate: new DateUtil().startOfDay().subtract(1, 'day').toDate(),
      endDate: new DateUtil().endOfDay().subtract(1, 'day').toDate(),
      type: 'past',
    },
    {
      title: 'Last 7 days',
      startDate: new DateUtil().startOfDay().subtract(6, 'days').toDate(),
      endDate: new DateUtil().endOfDay().toDate(),
      type: 'past',
    },
    {
      title: 'Last 30 days',
      startDate: new DateUtil().startOfDay().subtract(29, 'days').toDate(),
      endDate: new DateUtil().endOfDay().toDate(),
      type: 'past',
    },
    {
      title: 'Last 90 days',
      startDate: new DateUtil().startOfDay().subtract(89, 'days').toDate(),
      endDate: new DateUtil().endOfDay().toDate(),
      type: 'past',
    },
  ];

  protected dateRange$ = signal(this.createMatDateRange());
  @Input() minWidth?: number;
  @Input() maxWidth?: number;
  @Input() showFuturePresets = false;
  @Input() set dateRange(range: DateRange) {
    this.dateRange$.set(this.createMatDateRange(range))
  };
  @Output() change = new EventEmitter<DateRange | undefined>();
  readonly DateRangeSelectorView = DateRangeSelectorView;

  protected view: DateRangeSelectorView = DateRangeSelectorView.presets;

  setDate(date: Date): void {
    const start = this.dateRange$().start;
    const end = this.dateRange$().end;

    if (start && end) {
      this.dateRange$.set(new MatDateRange(date, null));
    } else if (start && !end) {

      if (date <= start) {
        this.dateRange$.set(new MatDateRange(date, start));
      } else {
        this.dateRange$.set(new MatDateRange(start, date));
      }
    }
  }

  clear(): void {
    this.change.emit();
  }

  close(dateRange?: DateRange): void {

    if (dateRange?.startDate && !dateRange?.endDate) {
      if (dateRange.startDate < new DateUtil().endOfDay().toDate()) {
        this.change.emit({ startDate: dateRange.startDate, endDate: new DateUtil().endOfDay().toDate() })
      } else {
        this.change.emit({
          startDate: new DateUtil(dateRange.startDate as Date).startOfDay().toDate(),
          endDate: new DateUtil(dateRange.startDate as Date).endOfDay().toDate(),
        })
      }
    } else {
      this.change.emit(dateRange);
    }
  }

  private createMatDateRange(range?: DateRange): MatDateRange<Date> {
    return new MatDateRange(
      this.extractDate(range?.startDate, this.presets[DateRangeSelectorComponent.DEFAULT_PRESET_INDEX].startDate as Date),
      this.extractDate(range?.endDate, this.presets[DateRangeSelectorComponent.DEFAULT_PRESET_INDEX].endDate as Date),
    );
  }

  private extractDate(date: Date | string | undefined, fallback: Date): Date {
    if (typeof date === 'string') {
      return new Date(date);
    }

    if (date instanceof Date) {
      return date;
    }

    return fallback
  }
}

<div>
  <kt-generic-button appearance="flat" color="primary" [disabled]="!!props.disabled" (click)="fileInput.click()">{{ props['text'] }}</kt-generic-button>
</div>
<input
  #fileInput
  type="file"
  name="file"
  [accept]="props['accept']"
  class="d-none"
  (change)="onChange(fileInput.files)"
/>

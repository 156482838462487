import { ChangeDetectionStrategy, Component, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-field-inline-scrollable-radio',
  templateUrl: './formly-field-inline-scrollable-radio.component.html',
  styleUrls: ['./formly-field-inline-scrollable-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInlineScrollableRadioComponent extends FieldType {
  override defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'custom', // 'custom' | 'custom-inline' | 'stacked' | 'inline'
    },
  };
}

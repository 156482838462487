import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-wrapper-label-tooltip',
  template: `
  <div class="wrapper">
    <label>
      {{ to.label }}
      @if(to.required) {
        <span>*</span>
      }
    </label>
    <mat-icon class="label__tooltip" [matTooltip]="to['labelTooltip']" matTooltipPosition="above" matTooltipClass="label-tooltip">info</mat-icon>
  </div>
  <ng-container #fieldComponent></ng-container>
`,
  styles: [
    '::ng-deep .label-tooltip { white-space: pre-line; }',
    '.wrapper { display: flex; align-items: center; justify-content: space-between; }',
    '.label__tooltip { font-size: 1.2rem !important }',
  ],
})
export class FormlyWrapperLabelTooltipComponent extends FieldWrapper { }

<div>
  <button
    [type]="to.type"
    [ngClass]="'btn btn-' + props['btnType']"
    (click)="onClick($event)"
    [disabled]="to.disabled"
  >
    {{ props['text'] }}
  </button>
</div>

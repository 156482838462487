import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateIntervalComponent } from './dates/date-interval/date-interval.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

const components = [
  DateIntervalComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  exports: [
    ...components,
  ],
})
export class FormControlsModule { }

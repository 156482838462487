import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateUtil } from '@core/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyDateRangeKey } from '@shared/formlyform/utils';

interface FormModel {
  [key: string]: any;
}

@Component({
  selector: 'kt-formly-form',
  template: `<ng-container *ngIf="fields">
    <formly-form [form]="$any(form)" [fields]="fields" [(model)]="model" (modelChange)="modelChanged($event)"></formly-form>
  </ng-container>`,
})
export class FormlyFormComponent implements AfterViewInit {
  @Input() fields?: FormlyFieldConfig[];
  @Input() form?: FormGroup = new FormGroup([]);
  @Input() model?: any = {};
  @Input() emitNullish = false;
  @Input() emitOnInit = false;

  @Output() modelChange = new EventEmitter();

  ngAfterViewInit(): void {
    if (this.emitOnInit) {
      this.modelChanged(this.model);
    }
  }

  modelChanged(model: FormModel): void {
    if (this.emitNullish) {
      this.modelChange.emit(this.flattenDateRange(model));
      return
    }

    const changedValue = Object.keys(model).reduce<Partial<any>>((obj, key) => {
      const val = (model as any)[key];
      if (typeof val === undefined || val === null || Array.isArray(val) && val.length === 0) {
        return obj;
      }
      return {
        ...obj,
        [key]: val,
      };
    }, {});

    this.modelChange.emit(this.flattenDateRange(changedValue));
  }

  private flattenDateRange(formModel: FormModel) {
    return Object.keys(formModel).reduce((model, key) => {
      if (key === FormlyDateRangeKey) {
        return {
          ...model,
          startDate: formModel[FormlyDateRangeKey].startDate,
          endDate: formModel[FormlyDateRangeKey].endDate,
        };
      }

      if (formModel[key].startDate && formModel[key].endDate) {
        const start = new Date(formModel[key].startDate);
        const end = new Date(formModel[key].endDate);

        if (DateUtil.isDateValid(start) && DateUtil.isDateValid(end)) {
          return {
            ...model,
            [`${key}Start`]: start?.toISOString(),
            [`${key}End`]: end?.toISOString(),
          };
        } else {
          return model;
        }
      } else if (formModel[key].startDate === null && formModel[key].endDate === null) {
        return model;
      }

      return {
        ...model,
        [key]: formModel[key],
      };
    }, {});
  }
}

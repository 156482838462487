export enum FormlyFieldType {
  AsyncSelect = 'asyncSelect',
  AutoRepeat = 'auto-repeat',
  Button = 'button',
  Checkbox = 'checkbox',
  MultiCheckbox = 'multicheckbox',
  Custom = 'custom',
  DateRange = 'date-range',
  FileUpload = 'file-upload',
  ImageUpload = 'image-upload',
  AssetUpload = 'asset-upload',
  Input = 'input',
  ColorPicker = 'color-picker',
  Repeat = 'repeat',
  Select = 'select',
  StyledRadio = 'styled-radio',
  Radio = 'radio',
  Textarea = 'textarea',
  Virtual = 'virtual',
  Text = 'text',
}

export enum FormlyWrapper {
  InlineLabel = 'inline-label',
  LabelTooltip = 'label-tooltip',
}

export enum FormlyValidator {
  NoSpacesAllowed = 'no-spaces-allowed',
}

<div class="table__row"
     [ngStyle]="{
      'cursor': cursor ? cursor : 'auto',
      'background-color': active ? 'lightblue' : hovered ? 'rgb(211, 211, 211, 0.4)' : bgColor || 'initial',
      'color': textColor || 'initial',
      }"
     (mouseover)="hovered = true"
     (mouseout)="hovered = false">
  <ng-content></ng-content>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection } from '@core/models';

@Component({
  selector: 'kt-table-column',
  templateUrl: './table-column.component.html',
  styleUrls: ['./table-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnComponent {
  protected sortState: SortDirection | undefined = undefined;

  @Input() align?: 'start' | 'center' | 'end' | 'justify' | 'initial' = 'initial';
  @Input() bgColor?: string;
  @Input() textColor?: string;
  @Input() fieldName?: string;
  @Input() set sortable(state: boolean) {
    if (state) {
      this.sortState = 'asc';
    }
  };
  @Output() sorted = new EventEmitter<{ sortField: string; sortDirection: 'asc' | 'desc'; }>()

  toggleSortState(): void {
    if (this.sortState && this.fieldName) {
      this.sortState = this.sortState === 'asc' ? 'desc' : 'asc';
      this.sorted.emit({
        sortDirection: this.sortState,
        sortField: this.fieldName
      })
    }
  }
}

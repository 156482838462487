<ng-select appendTo="body"
  [items]="items$ | async"
  [bindLabel]="props['labelProp']"
  [bindValue]="props['valueProp']"
  [typeahead]="typeahead"
  [formControl]="$any(formControl)"
  [multiple]="props['multiple']"
  [minTermLength]="props.minLength || 0"
  [placeholder]="props.placeholder || 'Select'"
  [loading]="props['loading'] === true ? true : $any(loading$ | async)"
  (open)="handleDropdownOpenEvent($event)"
>
</ng-select>

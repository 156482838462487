import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';

import { DateUtil } from '@core/utils';
import { DateRangeSelectorComponent } from '@shared/components/date-range-selector';
import { DateRange } from '@core/models';


@Component({
  standalone: true,
  selector: 'kt-date-range',
  templateUrl: './date-range.component.html',
  styleUrl: './date-range.component.scss',
  imports: [
    MatMenuModule,
    AsyncPipe,
    MatDialogModule,
    DateRangeSelectorComponent,
  ],
})
export class DateRangeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  private datePipe = inject(DatePipe);
  @ViewChild('menuTrigger') trigger?: MatMenuTrigger;

  readonly text$ = new BehaviorSubject<string>('');

  ngOnInit(): void {
    this.text$.next(this.preparePrettyRangeText(this.field.defaultValue))
  }

  preparePrettyRangeText(dateRange?: DateRange): string {
    if (!dateRange) {
      return '';
    }

    if (!(DateUtil.isDateValid(dateRange?.startDate) && DateUtil.isDateValid(dateRange?.endDate))) {
      return '';
    }

    const start = this.datePipe.transform(dateRange.startDate, 'MMM d, y');
    const end = this.datePipe.transform(dateRange.endDate, 'MMM d, y');

    return `${start} - ${end}`;
  }

  changeHandler(dateRange?: DateRange): void {
    if (!dateRange) {
      this.formControl.reset();
    } else {
      this.formControl.setValue({ startDate: dateRange.startDate, endDate: dateRange.endDate });
    }
    this.text$.next(this.preparePrettyRangeText(dateRange));
    this.trigger?.closeMenu();
  }
}

import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-wrapper-inline-label',
  template: `
  <div class="inline-label">
    <label class="mr-3">{{ to.label }}</label>
    <ng-container #fieldComponent></ng-container>
  </div>
`,
  styles: ['.inline-label { display: flex; align-items: baseline;}'],
})
export class FormlyWrapperInlineLabelComponent extends FieldWrapper { }

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-field-button',
  templateUrl: './formly-field-button.component.html',
  styleUrls: ['./formly-field-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldButtonComponent extends FieldType {
  onClick(event: Event) {
    if (this.props['onClick']) {
      this.props['onClick'](event);
    }
  }
}

<div #container>
  <ng-container *ngIf="{ options: selectOptions | async } as obs">
    <!-- it is not possible to use togather 'searchable' and 'addTag' props due to ng-select limitation -->
    <ng-container *ngIf="!props['isSearchable'] && props['allowTextInput']; else defaultTpl">
      <ng-select #select
                 class="formly-field-select"
                 (change)="selectedChange($event)"
                 appendTo="body"
                 [items]="obs.options"
                 [selectOnTab]="true"
                 [multiple]="isMultiple"
                 [bindLabel]="labelProp"
                 [bindValue]="valueProp"
                 [formControl]="customFormControl"
                 [placeholder]="to.placeholder || ''"
                 [clearable]="isClearable"
                 [attr.required]="to.required"
                 [groupBy]="groupBy || ''"
                 [addTag]="true"
                 [addTagText]="'Use custom ' + props.label?.toLocaleLowerCase() || 'value'">

        <ng-template ng-multi-label-tmp
                     let-items="items"
                     let-clear="clear">
          <ng-container *ngIf="isMultiple">
            <div class="ng-value"
                 *ngFor="let item of items | slice: 0:visibleOptionsTotal">
              <span class="ng-value-label"> {{ $any(item)[labelProp] || item }}</span>
              <span class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true">×</span>
            </div>
            <div class="ng-value"
                 *ngIf="items.length > visibleOptionsTotal">
              <!-- if you change this update also PLACEHOLDER_OPTION_LEN -->
              <span class="ng-value-label">{{ items.length - visibleOptionsTotal }} more...</span>
            </div>
          </ng-container>
        </ng-template>

        <ng-template ng-optgroup-tmp
                     let-item="item">
          <ng-container *ngIf="groupBy">
            {{ item[groupBy] || 'Unnamed group' }}
          </ng-container>
        </ng-template>

        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="not-found-text ng-option disabled">{{ to['notFoundText'] || 'No items found' }}</div>
        </ng-template>

      </ng-select>
    </ng-container>

    <ng-template #defaultTpl>
      <ng-select #select
                 class="formly-field-select"
                 (change)="selectedChange($event)"
                 appendTo="body"
                 [items]="obs.options"
                 [notFoundText]="to['notFoundText'] || 'No items found'"
                 [selectOnTab]="true"
                 [multiple]="isMultiple"
                 [bindLabel]="labelProp"
                 [bindValue]="valueProp"
                 [formControl]="customFormControl"
                 [placeholder]="to.placeholder || ''"
                 [searchable]="isSearchable"
                 [clearable]="isClearable"
                 [attr.required]="to.required"
                 [groupBy]="groupBy || ''">

        <ng-template ng-multi-label-tmp
                     let-items="items"
                     let-clear="clear">
          <ng-container *ngIf="isMultiple">
            <div class="ng-value"
                 *ngFor="let item of items | slice: 0:visibleOptionsTotal">
              <span class="ng-value-label"> {{ $any(item)[labelProp] || item }}</span>
              <span class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true">×</span>
            </div>
            <div class="ng-value"
                 *ngIf="items.length > visibleOptionsTotal">
              <!-- if you change this update also PLACEHOLDER_OPTION_LEN -->
              <span class="ng-value-label">{{ items.length - visibleOptionsTotal }} more...</span>
            </div>
          </ng-container>
        </ng-template>

        <ng-template ng-optgroup-tmp
                     let-item="item">
          <ng-container *ngIf="groupBy">
            {{ item[groupBy] || 'Unnamed group' }}
          </ng-container>
        </ng-template>

        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="not-found-text ng-option disabled">{{ to['notFoundText'] || 'No items found' }}</div>
        </ng-template>

      </ng-select>
    </ng-template>
  </ng-container>
</div>
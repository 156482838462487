import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-field-text',
  templateUrl: './formly-field-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldTextComponent extends FieldType<FieldTypeConfig> {
  constructor() {
    super()
  }
}

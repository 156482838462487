<div class="container">
  <div class="content" [ngClass]="{ 'wrap': to['wrap'] }">
    <div
      class="item"
      *ngFor="
        let option of to.options | formlySelectOptions: field | async;
        let i = index
      "
      [ngClass]="{
        'form-check': to['formCheck'].indexOf('custom') === -1,
        'form-check-inline': to['formCheck'] === 'inline',
        'custom-control-inline': to['formCheck'] === 'custom-inline'
      }"
    >
      <input
        type="radio"
        [id]="id + '_' + i"
        [class.form-check-input]="to['formCheck'].indexOf('custom') === -1"
        [class.custom-control-input]="to['formCheck'].indexOf('custom') === 0"
        [name]="field.name || id"
        [class.is-invalid]="showError"
        [attr.value]="option.value"
        [value]="option.value"
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        [attr.disabled]="option.disabled || formControl.disabled ? true : null"
      />
      <label
        [class.form-check-label]="to['formCheck'].indexOf('custom') === -1"
        [for]="id + '_' + i"
      >
        <ng-container *ngIf="to['template'] && to['models']; else defaultLabel">
          <ng-container
            [ngTemplateOutlet]="to['template']"
            [ngTemplateOutletContext]="{
              model: to['models'][option.value],
              selected: formControl.valueChanges | async
            }"
          ></ng-container>
        </ng-container>
        <ng-template #defaultLabel>
          {{ option.label }}
        </ng-template>
      </label>
    </div>
  </div>
  <!-- <button type="button" class="btn btn-link" (click)="showItems()">Show more...</button> -->
</div>

import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-field-file-upload',
  templateUrl: './formly-field-file-upload.component.html',
  styleUrls: ['./formly-field-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldFileUploadComponent extends FieldType {
  @ViewChild('fileInput') fileInput!: ElementRef;
  onChange(files: FileList | null) {
    if (this.props['onChange']) {
      this.props['onChange'](files);
      this.clearFileInput();
    }
  }

  private clearFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }

}

<input #menuTrigger="matMenuTrigger"
       #container
       [matMenuTriggerFor]="menu"
       class="form-control"
       [style.cursor]="'pointer'"
       [disabled]="field.formControl.disabled"
       [readonly]="!field.formControl.disabled"
       [value]="field.formControl.disabled ? '' : text$ | async">

<mat-menu #menu="matMenu"
          [xPosition]="'before'">
  <kt-date-range-selector (click)="$event.stopPropagation()"
                          [minWidth]="container.offsetWidth"
                          [maxWidth]="container.offsetWidth * 2"
                          [dateRange]="formControl.value"
                          [showFuturePresets]="props['showFuturePresets']"
                          (change)="changeHandler($event)"></kt-date-range-selector>
</mat-menu>

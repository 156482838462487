import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-field-repeat-section',
  templateUrl: './formly-field-repeat-section.component.html',
  styleUrls: ['./formly-field-repeat-section.component.scss']
})
export class FormlyFieldRepeatSectionComponent extends FieldArrayType {
  constructor() {
    super();
  }

  drop(event: CdkDragDrop<any>): void {
    if (!this.field.fieldGroup) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
      moveItemInArray(this.model, event.previousIndex, event.currentIndex);
    }
  }
}

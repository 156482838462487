<div class="chart-widget mat-typography">
  <div class="chart-widget__header">
    <div class="toolbar">
      <div class="toolbar__title">
        {{ title }}
      </div>
      <div class="toolbar__tools">
        @if(updatedAt) {
        updated {{ updatedAt | durationAsync:undefined:true | async }}
        }
        <kt-generic-button appearance="icon"
                           [loading]="loading"
                           (click)="refresh.emit()">refresh</kt-generic-button>
      </div>
    </div>
    <mat-progress-bar *ngIf="loading"
                      mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-divider></mat-divider>
  <div class="chart-widget__content">
    <div class="content__wrapper">
      <ng-container *ngIf="!loading; else loadingTpl">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loadingTpl>
  <div [style.min-height.px]="200">Loading...</div>
</ng-template>

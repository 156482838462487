import { ChangeDetectorRef, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { catchError, EMPTY, forkJoin } from 'rxjs';
import { Asset, DocumentFileExtension, FileAssetService, FileExtension, ImageFileExtension } from '@core/entities/asset';
import { FileUtil } from '@core/utils';

interface FileShape {
  extension?: FileExtension;
  index: number;
  uploading: boolean;
  url?: string;
}

@Component({
  selector: 'kt-asset-upload',
  templateUrl: './asset-upload.component.html',
  styleUrls: ['./asset-upload.component.scss']
})
export class FormlyFieldAssetUploadComponent extends FieldType<FieldTypeConfig> implements OnInit {
  private assetService = inject(FileAssetService);
  private cdRef = inject(ChangeDetectorRef);

  @ViewChild('fileInput') fileInput!: ElementRef;

  readonly IMAGE_EXTENSIONS = Object.values(ImageFileExtension);
  readonly DOCUMENT_EXTENSIONS = Object.values(DocumentFileExtension);

  get multiple() {
    return this.props['multiple'];
  }

  get maxNumberOfFiles(): number {
    return this.multiple ? Number.MAX_SAFE_INTEGER : 1;
  }

  get currentQuantity(): number {
    return (this.formControl.value || []).length;
  }

  get fileAddAllowed(): boolean {
    return this.maxNumberOfFiles > this.currentQuantity;
  }

  protected accept = '';

  protected fileShapes: FileShape[] = [];

  private assets: Asset[] = [];

  ngOnInit(): void {
    if (!this.props['assetType'] || !this.props['assetSubType']) {
      throw new Error(`"props.assetType" and "props.assetSubType" properties are required`);
    }

    this.initAllowedFileTypes();
    if (this.formControl.value?.length) {
      let fileShapes: FileShape[];
      if (this.multiple && Array.isArray(this.formControl.value)) {
        fileShapes = this.formControl.value.map((url: string, index: number) => {
          return {
            index,
            uploading: false,
            url,
            extension: FileUtil.getFileExtension(url),
          } as FileShape
        });
      } else if (!this.multiple && typeof this.formControl.value === 'string') {
        fileShapes = [{
          index: 0,
          uploading: false,
          url: this.formControl.value,
          extension: FileUtil.getFileExtension(this.formControl.value),
        } as FileShape]
      } else {
        throw new Error(`Invalid asset field setup`);
      }

      this.fileShapes = fileShapes;
    }
    this.formControl.valueChanges.pipe().subscribe(value => this.syncFilesState(value, this.multiple));
  }

  private initAllowedFileTypes() {
    this.accept = Array.isArray(this.props['accept']) ? this.props['accept'].map(type => `.${type}`).join(',') : this.props['accept'] || `.${ImageFileExtension.JPG}`;
  }

  protected addFiles(uploadedFiles: File[] | FileList | null): void {
    if (!uploadedFiles) {
      return;
    }

    if (!this.fileAddAllowed || uploadedFiles.length > (this.maxNumberOfFiles - this.currentQuantity)) {
      return;
    }

    const files = Array.from(uploadedFiles);

    const shapes: FileShape[] = [];
    files.forEach((file, index) => {
      shapes.push({
        index,
        uploading: true,
      })
    });

    this.fileShapes = [...this.fileShapes, ...shapes];

    forkJoin(files.map(file => this.assetService.uploadFile(file, this.props['assetType'], this.props['assetSubType']))).pipe(
      catchError(err => {
        console.log(err);
        this.assets = [];
        this.cdRef.markForCheck();

        return EMPTY;
      })
    ).subscribe(assets => {
      this.assets = assets;
      this.cdRef.markForCheck();
      this.clearFileInput();

      if (this.multiple) {
        this.formControl.setValue(this.assets.map(asset => asset.url));
      } else {
        this.formControl.setValue(this.assets[0].url);
      }
    });
  }

  protected removeFile(index: number): void {
    if (this.fileShapes[index]) {
      this.fileShapes = this.fileShapes.filter(shape => shape.index !== index);
      this.removeImage(index);
    }
  }

  private removeImage(index: number) {
    this.assets = this.assets.filter((asset, assetIdx) => assetIdx !== index);
    this.formControl.reset(null);
    this.formControl.markAsDirty();
  }

  protected trackByFn(index: number, shape: FileShape): number {
    return shape.index;
  }

  private syncFilesState(value: string, multiple = false) {
    if (multiple) {
      this.fileShapes = this.fileShapes.map((file, index) => {
        return { ...file, uploading: false, url: value[index], extension: FileUtil.getFileExtension(value[index]) };
      });
    } else {
      this.fileShapes = this.fileShapes.map((file, index) => {
        return { ...file, uploading: false, url: value, extension: FileUtil.getFileExtension(value) };
      });
    }
  }

  private clearFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { NgxColorsModule } from 'ngx-colors';

@Component({
  selector: 'kt-color-picker',
  standalone: true,
  imports: [
    NgxColorsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyColorPickerComponent extends FieldType<FieldTypeConfig> {

}

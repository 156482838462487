import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DocumentFileExtension, FileExtension, ImageFileExtension } from '@core/entities/asset';
import { FileUtil } from '@core/utils';

interface FileShape {
  extension?: FileExtension;
  index: number;
  uploading: boolean;
  url?: string;
}

@Component({
  selector: 'kt-formly-field-image-upload',
  templateUrl: './formly-field-image-upload.component.html',
  styleUrls: ['./formly-field-image-upload.component.scss']
})
export class FormlyFieldImageUploadComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;

  readonly IMAGE_EXTENSIONS = Object.values(ImageFileExtension);
  readonly DOCUMENT_EXTENSIONS = Object.values(DocumentFileExtension);

  get maxNumberOfFiles(): number {
    return this.props['multiple'] ? Number.MAX_SAFE_INTEGER : 1;
  }

  get currentQuantity(): number {
    return (this.formControl.value || []).length;
  }

  get fileAddAllowed(): boolean {
    return this.maxNumberOfFiles > this.currentQuantity;
  }

  protected accept = '';

  protected fileShapes: FileShape[] = [];

  ngOnInit(): void {
    this.initAllowedFileTypes();
    if (this.formControl.value?.length) {
      this.fileShapes = this.formControl.value.map((url: string, index: number) => {
        const fileExtension = FileUtil.getFileExtension(url);
        return {
          index,
          uploading: false,
          url,
          extension: fileExtension,
        } as FileShape
      });
    }
    this.formControl.valueChanges.pipe().subscribe(urls => this.syncFilesState(urls));
  }

  private initAllowedFileTypes() {
    this.accept = Array.isArray(this.props['accept']) ? this.props['accept'].map(type => `.${type}`).join(',') : this.props['accept'] || `.${ImageFileExtension.JPG}`;
  }

  onChange(files: File[] | null) {

    this.addFiles(files);

    if (this.props['onChange']) {

      if (!this.fileAddAllowed) {
        return;
      }

      this.props['onChange'](files);
      this.clearFileInput();
    }
  }

  removeImage(index: number) {
    if (this.props['onRemove']) {
      this.props['onRemove'](index);
    }
  }

  protected addFiles(uploadedFiles: File[] | FileList | null): void {
    if (!uploadedFiles) {
      return;
    }

    if (!this.fileAddAllowed || uploadedFiles.length > (this.maxNumberOfFiles - this.currentQuantity)) {
      return;
    }

    const files = Array.from(uploadedFiles);

    const shapes: FileShape[] = [];
    files.forEach((file, index) => {
      shapes.push({
        index,
        uploading: true,
      })
    });

    this.fileShapes = [...this.fileShapes, ...shapes];

    if (this.props['onChange']) {

      this.props['onChange'](files);
      this.clearFileInput();
    }
  }

  protected removeFile(index: number): void {
    if (this.fileShapes[index]) {
      this.fileShapes = this.fileShapes.filter(shape => shape.index !== index);
      this.removeImage(index);
    }
  }

  protected trackByFn(index: number, shape: FileShape): number {
    return shape.index;
  }

  private syncFilesState(urls: string) {
    this.fileShapes = this.fileShapes.map((file, index) => {
      const fileExtension = FileUtil.getFileExtension(urls[index]);
      return { ...file, uploading: false, url: urls[index], extension: fileExtension };
    });
  }

  private clearFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }
}

<div class="date-range"
     [style.min-width.px]="minWidth || 0"
     [style.max-width.px]="maxWidth || 'none'">
  @if(view === DateRangeSelectorView.presets) {
  <div class="presets">
    @for (preset of presets; track preset.title) {
    @if (preset.type === 'past' || (preset.type === 'future' && showFuturePresets)) {

    <kt-generic-button (click)="close({ startDate: preset.startDate, endDate: preset.endDate })">{{
      preset.title
      }}</kt-generic-button>
    }
    }
    <kt-generic-button (click)="view = DateRangeSelectorView.calendar">Custom range</kt-generic-button>
    <mat-divider></mat-divider>
    <div class="controls">
      <kt-generic-button appearance="stroked"
                         (click)="clear()">Clear</kt-generic-button>
      <kt-generic-button appearance="stroked"
                         (click)="close()">Cancel</kt-generic-button>
    </div>
  </div>
  }

  @if (view === DateRangeSelectorView.calendar) {
  <ng-container *ngIf="dateRange$() as range">
    <div class="calendar__view">
      <div class="calendars"
           attachDeviceWidth>
        <div class="calendar-card calendar-card-start">
          <mat-calendar [selected]="range"
                        [startAt]="range.start"
                        [comparisonStart]="range.start"
                        [comparisonEnd]="range.end"
                        (selectedChange)="setDate($event)"></mat-calendar>
        </div>
        <div class="calendar-card calendar-card-end">
          <mat-calendar [selected]="range"
                        [startAt]="range.end"
                        [comparisonStart]="range.start"
                        [comparisonEnd]="range.end"
                        (selectedChange)="setDate($event)"></mat-calendar>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="controls">
        <kt-generic-button appearance="stroked"
                           icon="arrow_back"
                           (click)="view = DateRangeSelectorView.presets">Presets</kt-generic-button>
        <kt-generic-button appearance="stroked"
                           (click)="clear()">Clear</kt-generic-button>
        <kt-generic-button appearance="stroked"
                           (click)="close()">Cancel</kt-generic-button>
        <kt-generic-button appearance="stroked"
                           color="primary"
                           (click)="close({ startDate: $any(range.start), endDate: $any(range.end) })">Apply</kt-generic-button>
      </div>
    </div>
  </ng-container>
  }
</div>

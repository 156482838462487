<div class="repeat-section">
  <div class="items"
       cdkDropList
       [cdkDropListData]="field.fieldGroup"
       (cdkDropListDropped)="drop($event)">
    <div class="item"
         *ngFor="let item of field.fieldGroup; let i = index"
         cdkDrag
         cdkDragLockAxis="y"
         [cdkDragDisabled]="!to['draggable']"
         [cdkDragData]="item">
      <div class="example-handle"
           cdkDragHandle>
        <ng-container *ngIf="to['draggable']">
          <mat-icon aria-hidden="false">drag_handle</mat-icon>
        </ng-container>
      </div>
      <formly-field [field]="item"
                    class="col"></formly-field>
      <div class="remove">
        <kt-generic-button appearance="icon"
                           [disabled]="props['disableRemove']?.expression && props['disableRemove'].expression(item)"
                           tooltipMessage="Remove item"
                           [tooltipDisabledMessage]="props['disableRemove']?.message || 'Remove disabled'"
                           (click)="remove(i)">
          delete
        </kt-generic-button>
      </div>
    </div>

    <div style="margin: 30px 0" *ngIf="!props['hideAddBtn']">
      <kt-generic-button appearance="flat"
                         color="primary"
                         [disabled]="props.disabled === true ? true : props['disableAddBtn$'] ? $any((props['disableAddBtn$'] | async)) : false"
                         (click)="add()">
        {{ props['addBtnText'] || 'Add' }}
      </kt-generic-button>
    </div>
  </div>
</div>
<div class="table__column"
     [style.text-align]="align"
     [ngStyle]="{
  'background-color': bgColor || 'inherit',
  'color': textColor || 'inherit',
  'text-decoration': sortState ? 'underline' : 'normal',
  'cursor': sortState ? 'pointer' : 'inherit',
  }"
     (click)="toggleSortState()">
  <ng-content></ng-content>
</div>

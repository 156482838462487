import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';

import { FormlyFieldSelectComponent } from './components/formly-field-select/formly-field-select.component';
import { FormlyFieldAssetUploadComponent, FormlyFieldAsyncSelectComponent, FormlyFieldInlineScrollableRadioComponent } from './components';
import { NgxAngularQueryBuilderModule } from 'ngx-angular-query-builder';
import { FormlyFieldType, FormlyValidator, FormlyWrapper } from './model';
import { FormlyFieldFileUploadComponent } from './components/formly-field-file-upload/formly-field-file-upload.component';
import { FormlyFieldImageUploadComponent } from './components/formly-field-image-upload/formly-field-image-upload.component';
import { FormlyFieldButtonComponent } from './components/formly-field-button/formly-field-button.component';
import { FormControlsModule } from '@shared/components/form-controls/form-controls.module';
import { FormlyFieldRepeatSectionComponent } from './components/formly-field-repeat-section/formly-field-repeat-section.component';
import { FormlyWrapperInlineLabelComponent, } from './wrappers/inline-label/inline-label.component';
import { FormlyWrapperLabelTooltipComponent } from './wrappers/label-tooltip/label-tooltip.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormlyFormComponent } from './components/formly-form/formly-form.component';
import {
  FormlyFieldAutoRepeatSectionComponent
} from './components/formly-field-auto-repeat-section/formly-field-auto-repeat-section.component';
import { GenericButtonComponent } from '@shared/ui/common/buttons';
import { DragDropDirective, InputTrimDirective, NoScrollDirective } from '@core/directives';
import { SimplePreloaderComponent } from '@shared/preloader/simple-preloader/simple-preloader.component';
import { SafePipe } from '@corebase/layout';
import { FormlyFieldInputComponent } from './components/formly-field-input/formly-field-input.component';
import { FormlyFieldTextComponent } from './components/formly-field-text/formly-field-text.component';
import { applyModelChangeDebounce } from './extensions/default.extension';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { FormlyColorPickerComponent } from './components/color-picker/color-picker.component';

const FORMLY_CUSTOM_TEMPLATES = [
  {
    name: FormlyFieldType.StyledRadio,
    component: FormlyFieldInlineScrollableRadioComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.DateRange,
    component: DateRangeComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.Select,
    component: FormlyFieldSelectComponent,
  },
  {
    name: FormlyFieldType.Input,
    component: FormlyFieldInputComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.ColorPicker,
    component: FormlyColorPickerComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.AsyncSelect,
    component: FormlyFieldAsyncSelectComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.Button,
    component: FormlyFieldButtonComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        btnType: 'default',
        type: 'button',
      },
    },
  },
  {
    name: FormlyFieldType.FileUpload,
    component: FormlyFieldFileUploadComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        type: 'button',
        btnType: 'default',
        text: 'upload file',
      },
    },
  },
  {
    name: FormlyFieldType.ImageUpload,
    component: FormlyFieldImageUploadComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        type: 'button',
        btnType: 'default',
        text: 'upload file',
      },
    },
  },
  {
    name: FormlyFieldType.AssetUpload,
    component: FormlyFieldAssetUploadComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.Repeat,
    component: FormlyFieldRepeatSectionComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.AutoRepeat,
    component: FormlyFieldAutoRepeatSectionComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.Text,
    component: FormlyFieldTextComponent,
    wrappers: ['form-field'],
  }
];

const WRAPPERS = [
  { name: FormlyWrapper.InlineLabel, component: FormlyWrapperInlineLabelComponent },
  { name: FormlyWrapper.LabelTooltip, component: FormlyWrapperLabelTooltipComponent },
];

const MaterialModules = [
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule,
  MatTooltipModule,
];

const Components = [
  FormlyFormComponent,
  FormlyFieldInlineScrollableRadioComponent,
  FormlyFieldAsyncSelectComponent,
  FormlyFieldSelectComponent,
  FormlyFieldButtonComponent,
  FormlyFieldFileUploadComponent,
  FormlyFieldImageUploadComponent,
  FormlyFieldAssetUploadComponent,
  FormlyFieldRepeatSectionComponent,
  FormlyFieldAutoRepeatSectionComponent,
  FormlyWrapperInlineLabelComponent,
  FormlyWrapperLabelTooltipComponent,
  FormlyFieldInputComponent,
  FormlyFieldTextComponent,
];

const noSpacesAllowedValidator = (control: AbstractControl): ValidationErrors | null => !control.value || /^\S*$/gm.test(control.value) ? null : { [FormlyValidator.NoSpacesAllowed]: true };

const minLengthValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `Should have atleast ${field.props?.minLength} characters`;
}

const maxLengthValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `This value should be less than ${field.props?.maxLength} characters`;
}

const minValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `This value should be equal or more than ${field.props?.min}`;
}

const maxValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `This value should be equal or less than ${field.props?.max}`;
}

@NgModule({
  declarations: [
    ...Components,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormlySelectModule,
    FormlyMatToggleModule,
    NgxAngularQueryBuilderModule,
    FormlyMatFormFieldModule,
    FormlyModule.forRoot({
      extensions: [
        // { name: 'defaultOptions', extension: { prePopulate: applyModelChangeDebounce } },
      ],
      types: FORMLY_CUSTOM_TEMPLATES,
      wrappers: WRAPPERS,
      validators: [
        { name: FormlyValidator.NoSpacesAllowed, validation: noSpacesAllowedValidator },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: FormlyValidator.NoSpacesAllowed, message: 'Spaces are not allowed for this field' },
      ],
    }),
    FormlyBootstrapModule,
    FormControlsModule,
    DragDropModule,
    ...MaterialModules,
    NgxDaterangepickerMd,
    GenericButtonComponent,
    DragDropDirective,
    SimplePreloaderComponent,
    SafePipe,
    NoScrollDirective,
    InputTrimDirective,
  ],
  exports: [
    ReactiveFormsModule,
    FormlyModule,
    ...Components,
  ],
})
export class FormlyformModule { }

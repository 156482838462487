import { ChangeDetectionStrategy, Component, ElementRef, OnInit, OnDestroy, ViewChild, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType, } from '@ngx-formly/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isObservable, Observable, of } from 'rxjs';

export interface FormlyFieldSelectOption {
  label: string;
  value: string;
}

export interface FormlyFieldSelectOptions {
  [index: number]: FormlyFieldSelectOption;
}

const DEFAULT_VISIBLE_OPTIONS_TOTAL = 5;

@Component({
  selector: 'kt-formly-field-select',
  templateUrl: './formly-field-select.component.html',
  styles: ['.not-found-text { white-space: break-spaces !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldSelectComponent extends FieldType implements OnInit, OnDestroy {
  selectOptions!: Observable<FormlyFieldSelectOption[]>;
  isClearable!: boolean;
  isMultiple!: boolean;
  isSearchable!: boolean;
  groupBy: string | undefined = undefined;

  labelProp!: string;
  valueProp!: string;

  visibleOptionsTotal = DEFAULT_VISIBLE_OPTIONS_TOTAL;

  get customFormControl() {
    return this.formControl as UntypedFormControl;
  }
  @ViewChild('container') container!: ElementRef;

  get observableOptions(): boolean {
    return isObservable(this.props.options);
  }

  @ViewChild('select') select?: NgSelectComponent;
  private onScroll = () => {
    if (this.select && this.select.isOpen) {
      this.select?.dropdownPanel?.adjustPosition();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);

    if (!!this.observableOptions) {
      this.selectOptions = (this.props.options || []) as Observable<FormlyFieldSelectOption[]>;
    } else {
      this.selectOptions = of((this.props.options || []) as FormlyFieldSelectOption[]);
    }

    this.isClearable = typeof this.props['clearable'] === 'boolean' ? this.props['clearable'] : false;
    this.isMultiple = typeof this.props['multiple'] === 'boolean' ? this.props['multiple'] : false;
    this.isSearchable = typeof this.props['searchable'] === 'boolean' ? this.props['searchable'] : false;
    this.groupBy = typeof this.props['groupBy'] === 'string' ? this.props['groupBy'] : undefined;
    this.labelProp = this.props['labelProp'] as string || 'label';
    this.valueProp = this.props['valueProp'] || 'value';
  }

  selectedChange(selectedOptions: any[]) {
    if (this.isMultiple) {
      this.visibleOptionsTotal = this.calcVisibleOptionsTotal(selectedOptions);
    }
  }

  private calcVisibleOptionsTotal(selectedOptions: { [key: string]: string; }[] | string[]) {

    if (selectedOptions.some(option => {
      if (typeof option === 'string') {
        return !option.length;
      }

      return !(option[this.labelProp]?.length);
    })) {
      return DEFAULT_VISIBLE_OPTIONS_TOTAL;
    }

    const AVG_CHAR_SIZE = 12; // px
    const OPTION_CONTAINER_LEN = 35; // px
    const PLACEHOLDER_OPTION_LEN = 55; // px
    const options = selectedOptions.map(x => {
      if (typeof x === 'string') {
        return x;
      }

      return x[this.labelProp];
    });

    const calcTotalLen = (opts: any[]) => opts.reduce((len: number, opt) => {
      return len + (opt.length * AVG_CHAR_SIZE) + OPTION_CONTAINER_LEN;
    }, 0)

    while (calcTotalLen(options) > this.container.nativeElement.offsetWidth - PLACEHOLDER_OPTION_LEN) {
      options.pop()
    }
    return options.length || DEFAULT_VISIBLE_OPTIONS_TOTAL;
  }
}

<div class="upload-area"
     [ngClass]="{ 'upload-area--disabled': !props['multiple'] && formControl.value?.length }"
     fileDragDrop
     (fileDropped)="addFiles($event)">
  <div class="images">
    <ng-container *ngFor="let shape of fileShapes; let i = index; trackBy: trackByFn">
      <ng-container *ngIf="shape.uploading; else objectTpl">
        <div class="image image-shape">
          <kt-simple-preloader></kt-simple-preloader>
        </div>
      </ng-container>

      <ng-template #objectTpl>
        <div class="image">
          <ng-container *ngIf="shape.extension && IMAGE_EXTENSIONS.includes($any(shape.extension))">
            <img class="image__preview"
                 width="100px"
                 height="100px"
                 [src]="shape.url | safe:'resourceUrl'"
                 alt="Image">
          </ng-container>
          <ng-container *ngIf="shape.extension && DOCUMENT_EXTENSIONS.includes($any(shape.extension))">
            <ng-container *ngIf="shape.extension === 'pdf'; else docIconTpl">
              <mat-icon>picture_as_pdf</mat-icon>
            </ng-container>
            <ng-template #docIconTpl>
              <mat-icon>contract</mat-icon>
            </ng-template>
          </ng-container>
          <div class="image__remove">

            <kt-generic-button tooltipMessage="Remove image"
                               appearance="icon"
                               color="black"
                               (click)="removeFile(i)">delete</kt-generic-button>
          </div>
        </div>
      </ng-template>

    </ng-container>
  </div>

  <ng-container *ngIf="props['multiple'] || !props['multiple'] && !formControl.value?.length">

    <div class="upload-area__description">
      Drag and drop images here or <a [ngStyle]="{ cursor: 'pointer', color: 'blue' }"
         (click)="fileInput.click()">browse</a>
    </div>
  </ng-container>
</div>

<input #fileInput
       type="file"
       name="file"
       [multiple]="props['multiple'] || false"
       [accept]="accept"
       hidden
       (change)="addFiles($any(fileInput.files))" />
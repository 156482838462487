import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'kt-formly-field-auto-repeat-section',
  template: `<formly-field *ngFor="let field of field.fieldGroup" [field]="field"></formly-field>`,
})
export class FormlyFieldAutoRepeatSectionComponent extends FieldArrayType {

}

import { Component } from '@angular/core';
import { environment } from '@environment';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, EMPTY, Observable, Subject, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-async-select',
  templateUrl: './formly-field-async-select.component.html',
  styleUrls: ['./formly-field-async-select.component.scss']
})
export class FormlyFieldAsyncSelectComponent extends FieldType {
  readonly loading$ = new BehaviorSubject<boolean>(false);
  readonly dropdownOpenEvt$ = new Subject<unknown>();
  readonly typeahead = new Subject<string>();
  readonly items$?: Observable<any[]> = merge(
    this.dropdownOpenEvt$,
    this.typeahead.pipe(
      filter(Boolean),
      debounceTime(environment.ux.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ),
  ).pipe(
    tap(() => this.loading$.next(true)),
    switchMap((keyword: string | unknown) => this.props['getOptions'](keyword || '') as any[]),
    tap(() => this.loading$.next(false)),
    finalize(() => this.loading$.next(false)),
    catchError(() => {
      this.loading$.next(false);
      return EMPTY;
    })
  );

  handleDropdownOpenEvent(evt: unknown): void {
    if (!this.props['preloadWhenOpen']) {
      return;
    };

    this.dropdownOpenEvt$.next(evt);
  }
}

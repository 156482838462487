import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { GenericButtonComponent } from '../common';
import { DurationAsyncPipe } from '@core/pipes/duration-async.pipe';

@Component({
  standalone: true,
  selector: 'kt-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
  imports: [
    AsyncPipe,
    DurationAsyncPipe,
    GenericButtonComponent,
    MatDividerModule,
    MatIconModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgIf,
  ],
})
export class WidgetComponent {
  @Input() title?: string;
  @Input() loading: boolean | null = false;
  @Input({ required: false }) updatedAt: Date | null = null;
  @Output() refresh = new EventEmitter();
}
